import request from '@/plugins/axios'
// 话术列表
export const apikefuLangLists = (params: any) => request.get('/settings.kefu_lang/lists', { params })

// 添加话术
export const apikefuLangAdd = (params: any) => request.post('/settings.kefu_lang/add', params)

// 编辑话术
export const apikefuLangEdit = (params: any) => request.post('/settings.kefu_lang/edit', params)

// 删除话术
export const apikefuLangDel = (params: any) => request.post('/settings.kefu_lang/del', params)