var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"speech-edit"},[_c('ls-dialog',{ref:"lsDialog",attrs:{"async":true,"width":"740px","top":"20vh","title":_vm.value.id ? '编辑客服话术' : '新客服话术',"confirmButtonText":"保存"},on:{"cancel":_vm.closeDialog,"confirm":_vm.handleSave}},[_c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.value,"label-width":"120px","size":"small"}},[_c('el-form-item',{attrs:{"label":"话术标题","required":"","prop":"title","rules":[
                        {
                            required: true,
                            message: '请输入话术标题',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('el-input',{staticStyle:{"width":"380px"},attrs:{"placeholder":"请输入话术标题"},model:{value:(_vm.value.title),callback:function ($$v) {_vm.$set(_vm.value, "title", $$v)},expression:"value.title"}})],1),_c('el-form-item',{attrs:{"label":"话术内容","required":"","prop":"content","rules":[
                        {
                            required: true,
                            message: '请输入话术内容',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('el-input',{staticStyle:{"width":"380px"},attrs:{"type":"textarea","placeholder":"多行输入","rows":10},model:{value:(_vm.value.content),callback:function ($$v) {_vm.$set(_vm.value, "content", $$v)},expression:"value.content"}})],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{staticStyle:{"width":"220px"},attrs:{"type":"number","placeholder":""},model:{value:(_vm.value.sort),callback:function ($$v) {_vm.$set(_vm.value, "sort", $$v)},expression:"value.sort"}}),_c('div',{staticClass:"xs muted"},[_vm._v("排序值必须为整数；数值越小，越靠前")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }